<template>
    <div class="container my-3">
        <div class="row align-content-center" v-if="projects">             

            <div class="col-lg-5 mb-2 mx-auto px-0">

                <CurrentProject v-if="current"
                    :current="current"
                    @quit="xtachUser({action:'detach',pid:current.id, uid:auth.id,func:'getMyProjects'})"
                    @save="changeItem('projects',current)"
                    @del="delItem('projects',$event,index('projects',$event),'project.delete')" 
                    @undo="getMyProjects()"
                />

                <ProjectIntro v-else />

            </div>

            <div class="col-lg-6 mx-auto px-0">

                <ProjectList v-if="projects"
                    :projects="projects" 
                    :current="current"
                    @open="setProject($event)" />

                <ProjectSearch 
                    :found="found" 
                    :projects="projects" 
                    @search="findProject($event)" 
                    @join="xtachUser({action:'attach',pid:$event,uid:auth.id,func:'getMyProjects'})" />

            </div>

            <CreateProject @save="createProject($event)" />

            <PlusButton @click="$bvModal.show('new-project')" /> 
 
         </div>
    </div>
</template>

<script>
import PlusButton from "@/views/BaseComponents/PlusButton.vue";
import CurrentProject from "@/views/ProjectComponents/CurrentProject.vue";
import ProjectList from "@/views/ProjectComponents/ProjectList.vue";
import ProjectSearch from "@/views/ProjectComponents/ProjectSearch.vue";
import CreateProject from "@/views/ProjectComponents/CreateProject.vue";
import ProjectIntro from "@/views/ProjectComponents/ProjectIntro.vue";

export default {
    name: "Project",
    components: {
        PlusButton, 
        CurrentProject, 
        ProjectList, 
        ProjectSearch,
        CreateProject,
        ProjectIntro
    },
    /**
     * MOUNTED
     */
    async created() { await this.getMyProjects(); },
    watch: { projects: function() { this.setProject(); } },
    /**
     * DATA
     */
    data() {return{
        projects: null,
        current: null,
        found: null
    }},
    /**
     * METHODS
     */
    methods: {
        // get my projects
        getMyProjects() { this.axon("get","projects?my=1","projects"); },
        // set current
        setProject(id=null) {
            this.current = null;
            if(this.projects.length<1) { this.saveLast(null); return false; }
            let ix = 0;
            let localID = this.auth.project ? this.auth.project.id : null;
            // if not passed
            if(id==null) {
                ix = this.index('projects',localID);
                if(localID && this.projects[ix]) id = localID;
                else id = this.projects[0].id;
            }
            ix = this.index('projects',id);
            this.current = this.projects[ix]; 
            this.notify = ['Members',this.projects[ix].info.requests];
            this.notify = ['Board',this.projects[ix].info.newposts];
            // if different = update
            if(!this.auth.project|| id!=this.auth.project.id) this.saveLast(id);
            this.goTop();
            },
        // save last project id
        async saveLast(id) { 
            if(id) { await this.axon('put','users/'+this.auth.id,{pid:id}); this.authUser(); }
        },
        
        // join a project
        joinProject() { },

        // find a project
        findProject(event=null) {
            if(event) this.axon("get","projects?find="+event,"found"); 
            else this.found = null; },

        // create project
        async createProject(data) {
            let id = await this.createItem('project',data);
            if(id) this.setProject(id); },


    },



}
</script>