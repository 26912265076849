<template>
<div>
        <b-card v-if="current" header-bg-variant="primary" header-text-variant="white" :header="$t('project.current') + ': ' + current.id">
        <b-card-title>

            <b-form-input v-if="edit"
                v-model="current.title" 
                :placeholder="$t('holder.title')" 
                class="text-primary bg-white text-big text-center break-lines"
                maxlength="50"
                :disabled="!edit"
                @mouseleave.native="edit=false"
            />

            <p v-else 
                class="text-big text-primary p-2"
                @mouseover="current.role=='admin' ? edit=true : null"
                v-text="current.title"
                />

        </b-card-title>      
        <b-card-text class="text-secondary">
            
            <!-- project info -->
            <p class="text-small text-muted" 
                v-html="$t('project.info', {
                    date: current.created_at, 
                    members: many('members'), 
                    files: many('files'), 
                    tasks: many('tasks')
                })" />
            
            <p v-if="current.role=='admin' 
                    && current.info.requests>0" 
                    class="text-danger text-small pointer"
                    @click="$router.push({name:'Members'})">
                <b>{{ $t('project.request') }} {{ current.info.requests }}</b>
            </p>
            
            <!-- project info -->
            <b-form-textarea v-if="edit"
                v-model="current.description"
                :placeholder="$t('holder.description')"
                rows="1"
                max-rows="10"
                class="bg-white"
                :disabled="!edit"
                @mouseleave.native="edit=false"
            />

            <p v-else 
                class="text-dark text-left p-2 limit-height"
                @mouseover="current.role=='admin' ? edit=true : null"
                v-html="desc_html"
                />

            <p :class="[current.role=='request' ? 'text-danger' : 'text-muted', 'text-center p-2 text-small my-0']">
                {{ $t('project.role') }}: {{ $t('role.'+current.role) }}</p>

            <!-- edit -->
            <b-button v-if="current.role=='admin'" variant="success mx-2 my-2" @click="$emit('save',current.id)">
                <b-icon icon="pencil-square" /> {{ $t('button.save') }}
            </b-button>

            <!-- undo -->
            <b-button v-if="current.role=='admin'" variant="primary mx-2 my-2" @click="$emit('undo')">
                <b-icon icon="arrow-counterclockwise" /> {{ $t('button.undo') }}
            </b-button>

            <!-- quit -->
            <b-button v-else variant="info mx-2 my-2" @click="$emit('quit',current.id)">
                <b-icon icon="box-arrow-right" /> {{ $t('button.quit') }}
            </b-button>
            
            <!-- delete -->
            <b-button v-if="current.role=='admin'" variant="danger mx-2 my-2" @click="$emit('del',current.id)">
                <b-icon icon="x" /> {{ $t('button.delete') }}
            </b-button>

        </b-card-text>
        </b-card>
</div>
</template>
<script>
export default {
    props: ['current'],
    data() {return{
        edit: false,
    }},
    computed: {
        desc_html() {
            if(this.current.description) return this.current.description.replace(/(\r\n|\n\r|\r|\n)/g, '<br/>' + '$1');
            else return null;
        }
    },
    methods: {
        /**
        * Change zero to no in info fields
        */
        many(field) {
            if(this.current.info[field]>0) 
                return this.current.info[field]; 
            else return "0"; },
    }
}
</script>