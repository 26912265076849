<template>
    <div>
        <b-card v-if="current" header-bg-variant="primary" header-text-variant="white" :header="$t('project.my')" no-title>
        <b-card-text>
            <b-list-group>

                <!-- my projects list -->
                <b-list-group-item v-for="project in projects" :key="project.id" class="text-left overflow-auto">
                    <span class="text-tiny text-info">[{{ project.id }}]</span> 
                     {{ project.title }} 
                     <!-- request info for admin-->
                     <span v-if="project.role=='admin' && project.info.requests" class="req">{{ project.info.requests }}</span>
                     <span v-if="project.info.newposts" class="req bg-primary">{{ project.info.newposts }}</span>

                    <!-- control buttons -->
                    <div class="float-right">
                        <!-- info -->
                        <b-button 
                            variant="outline-primary" 
                            size="sm" 
                            class="ml-1" 
                            @click="$bvModal.msgBoxOk(project.description, {
                                centered: true, footerClass: 'd-none',
                                headerBgVariant: 'primary', headerTextVariant: 'white',
                                hideHeaderClose: false, hideHeader: false,
                                title: project.title })">
                            <b-icon icon="info-square" />
                        </b-button>
                        <!-- enter -->
                        <b-button 
                            :variant="project.id == current.id ? 'success' : project.role == 'request' ? 'danger' : 'primary'" 
                            size="sm" 
                            class="ml-1" 
                            @click="$emit('open',project.id)">
                            <b-icon :icon="project.role == 'request' ? 'question-square' : 'box-arrow-in-right'" />
                        </b-button>



                    </div>

                </b-list-group-item>
            </b-list-group>
        </b-card-text>
        </b-card>  
    </div>
</template>

<script>
export default {
    props: ['projects','current'],
}
</script>