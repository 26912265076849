<template>
    <div>
        <b-modal 
            id="new-project"  
            ref="new-project"
            :title="$t('project.create')" 
            class="pl-0"
            centered
            headerBgVariant="primary"
            headerTextVariant="white"
            busy>

            <!-- title -->
            <b-form-input 
                v-model="project.title" 
                :placeholder="$t('holder.title')" 
                class="text-primary text-big text-center mb-3 break-lines"
                maxlength="50"
            />

            <!-- description -->
            <b-form-textarea
                v-model="project.description"
                :placeholder="$t('holder.description')"
                rows="1"
                max-rows="6"
                class="my-1"
            />

            <template v-slot:modal-footer>
                <!-- save -->
                <b-button variant="success mx-2 my-2" @click="$emit('save',project)">
                    <b-icon icon="pencil-square" /> {{ $t('button.save') }}
                </b-button>

                <!-- cancel -->
                <b-button variant="secondary mx-2 my-2" @click="$refs['new-project'].hide()">
                    <b-icon icon="x" /> {{ $t('button.cancel') }}
                </b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
export default {
    data() {return{
        project: { title: undefined, description: undefined }
    }}
}
</script>