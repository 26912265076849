<template>
        <div class="card my-2">
                <div class="card-body pt-0">
                    <SearchBar :text="$t('project.find')" v-model="search" @click="$emit('search',search)" />
                    <!-- list -->
                    <b-list-group-item v-for="p in found" :key="p.id" :variant="projects[p.id] ? 'light' : 'white'"
                        class="text-left overflow-auto text-small">
                        <span class="text-tiny text-info">[{{ p.id }}]</span> {{ p.title }}
                    <!-- control buttons -->
                    <div class="float-right">

                        <b-button v-if="!projects[index('projects',p.id)]" 
                            variant="primary" 
                            size="sm" 
                            class="ml-1" 
                            @click="$emit('join',p.id)" >
                            <b-icon icon="plus" />
                        </b-button>

                        <b-button v-else
                            disabled
                            variant="secondary"
                            size="sm"
                            class="ml-1">
                            <b-icon icon="check" />
                        </b-button>

                    </div>
                    </b-list-group-item>
                    <span class="text-tiny text-muted" v-html="$t('project.titlesearch')" />
                </div>
        </div>
</template>

<script>
import SearchBar from "@/views/BaseComponents/SearchBar.vue";

export default {
    components: { SearchBar },
    props: ['found','projects'],
    data() {return{
        search: null
    }}
    
}
</script>